import React from 'react';
import { graphql } from 'gatsby';

//Components
import Layout from '../components/layout';
import SEO from '../components/seo';

//Template
import SectorsPageTemplate from '../templates/pages/sectors';

const SectorsPage = ({ data, pageContext }) => {
  return (
    <Layout lang={pageContext.langKey}>
      <SEO
        title={data.pagesJson.languages.en.metaData.title}
        description={data.pagesJson.languages.en.metaData.description}
      />

      <SectorsPageTemplate data={data.pagesJson.languages.en} lang="en" />
    </Layout>
  );
};

export const SECTORS_PAGE_EN_QUERY = graphql`
  query SECTORS_PAGE_EN_QUERY {
    pagesJson(pageKey: { eq: "sectors" }) {
      languages {
        en {
          metaData {
            title
            description
          }
          intro {
            title
            text
            media {
              childImageSharp {
                fluid(maxWidth: 1800) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
              publicURL
            }
            thumbnailVideo
            fullVideo
          }
          information {
            text
            title
          }
          sectors {
            title
            entry
            image {
              childImageSharp {
                fluid(maxWidth: 696, maxHeight: 464) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
              publicURL
            }
          }
        }
      }
    }
  }
`;

export default SectorsPage;
